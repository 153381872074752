.card {
  .contentWrapper {
    margin: 80px auto 15px auto;
    width: 90%;
  }
  .title {
      margin-bottom: 70px;
      font-size: 23px;
      font-weight: 900;
      line-height: 1.4;
      text-align: center
  }
  .content {
      padding: 0 10px;
      font-size: 20px;
      line-height: 1.5;
      text-align: left
  }
}

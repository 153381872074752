@import "./mixin";

#Contact {
  // background: #333;
  flex: 1;
  box-sizing: border-box;
  @include fontFamily;

  .outer-wrapper {
    width: 100%;
    height: 100%;
    background-image: url("../img/portfolio/contact.jpg");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: inherit;
    height: inherit;
    background-color: rgba(255, 255, 255, 0.4);

    @include breakpoint($mobileL) {
      position: fixed;
    }
  }

  .inner-wrapper {
    width: 100%;
    height: 100%;
    padding: 40px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contact-card {
    width: 1300px;
    display: flex;
    flex-wrap: nowrap;
    background: white;
    box-shadow: 8px 8px 8px 0px rgba(0, 0, 0, 0.6);

    @include breakpoint($tabletL) {
      width: 750px;
      max-height: 100%;
      overflow-y: auto;
    }

    @include breakpoint($tablet) {
      width: 90%;
      flex-wrap: wrap;
    }
  }

  .left-wrapper {
    box-sizing: border-box;
    background-color: black;
    padding: 50px 120px 50px 50px;

    @include breakpoint($tablet) {
      width: 100%;
    }

    @include breakpoint($mobileL) {
      padding: 20px
    }
  }

  .contact-info {
    color: white;
    font-size: 35px;
    font-weight: 700;

    @include breakpoint($mobileL) {
      font-size: 25px;
    }
  }

  .info-wrapper {
    display: flex;
    flex-direction: column;
  }

  .info-item {
    width: 100%;
    margin: 15px 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;

    @include breakpoint($mobileL) {
      margin: 5px 0;
    }
  }

  .info-text {
    color: white;
    margin-left: 10px;
  }

  .right-wrapper {
    flex: 1;
    padding: 50px;

    @include breakpoint($tablet) {
      width: 100%;
      flex: none;
    }

    @include breakpoint($mobileL) {
      padding: 20px;
    }
  }

  .right-inner-wrapper {
    width: 100%;
  }

  .thanks-text {
    font-size: 20px;
    font-weight: 600;

    @include breakpoint($mobileL) {
      font-size: 15px;
    }
  }

  .form-wrapper {
    width: 100%;
    margin: 15px 0;
  }

  .form {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }

  input,
  textarea {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #bcbcbc;
    margin: 15px 0;

    @include breakpoint($mobileL) {
      margin: 5px 0;
    }
  }

  input:focus,
  textarea:focus {
    outline: none;
  }

  input {
    width: 45%;
    padding: 10px 0;

    @include breakpoint($mobileL) {
      width: 100%;
    }
  }

  textarea {
    width: 100%;
    resize: none;
    padding-left: 0;
    padding-right: 0;
  }

  .send-button {
    color: white;
    background-color: $themeColor;
    padding: 10px 25px;
    font-size: 20px;
    font-weight: 600;
    border: none;
    outline: none;

    &:hover {
      background: #cf3352
    }

    @include breakpoint($mobileL) {
      width: 100%;
    }
  }

  .success-msg {
    margin: 20px 0;
    color: rgb(5, 189, 118);

    @include breakpoint($mobileL) {
      margin: 8px 0;
    }
  }

  .error-msg {
    margin: 20px 0;
    color: red;

    @include breakpoint($mobileL) {
      margin: 8px 0;
    }
  }

}
@import url("https://fonts.googleapis.com/css2?family=Baloo+2:wght@600;700;800&family=Poppins:wght@400;600;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Pacifico&display=swap');

$tabletL: 1024px;
$tablet: 768px;
$mobileL: 568px;
$mobileM: 414px;
$mobileS: 375px;

$themeColor: #fe4066;

@mixin breakpoint($point) {
    @media (max-width: $point) {
        @content;
    }
}

@mixin fontFamily {
    // font-family: "Baloo 2", cursive;
    font-family: 'Montserrat', sans-serif;
}

@mixin iconFamily {
    font-family: 'Pacifico', cursive;
}
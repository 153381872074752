@import "./mixin";

#Projects {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 0;
  @include fontFamily;

  p {
    margin: 0;
  }

  .outer-wrapper {
    width: 100%;
    padding: 90px 0;
    max-width: 1900px;
  }

  .inner-wrapper {
    width: 80%;
    margin: auto;
  }

  .project-section-title {
    font-size: 30px;
    font-weight: bold;
    padding-bottom: 10px;
  }

  .project-cards-wrapper {
    width: 70%;
    margin: 80px auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;

    @include breakpoint($tabletL) {
      width: 100%;
    }

    @include breakpoint($mobileL) {
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
    }
  }

  .project-card {
    width: 300px;
    height: 300px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    @include breakpoint($tabletL) {
      width: 250px;
      height: 250px;
    }

    @include breakpoint($mobileL) {
      width: 250px;
      height: 250px;
      margin: 20px 0;
    }
  }

  .project-logo {
    width: 100%;
  }

  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity .5s ease-out;
  }

  .project-card:hover .overlay {
    opacity: 1;
  }
}
#Navbar {
    position: absolute;
    width: 100%;
    height: 100%;

    .fa-bars {
        color: white;
        font-size: 25px;
        margin: 25px 25px;
        cursor: pointer;
        position: absolute;
        z-index: 2;
    }
    
    .overlay {
        position: absolute;
        width: 0%;
        height: 100%;
        top: 0;
        right: 0;
        background: rgba(0, 0, 0, 0);
        display: flex;
        justify-content: space-around;
        align-items: center;
        transition: all 1.5s ease-in;
        z-index: 1;
        overflow: hidden;
    }
    
    .overlay-fadein {
        height: inherit;
        width: 100%;
        background: rgba(0, 0, 0, .3);
        overflow: hidden;
    }
    
    .navbar {
        transition: all 1.5s ease;
        opacity: 0;
        margin-bottom: 25px;
        overflow: hidden;
        width: 0;
        transition-delay: 1s
    }
    
    .navbar-fadein {
        opacity: 1;
        margin-bottom: 0;
        overflow: hidden;
        width: 100%;
    }
    
    ul {
        list-style: none;
        overflow: hidden;
    }
    
    .nav-item {
        color: white;
        font-size: 50px;
        padding: 15px 0;
        font-weight: bold;
        cursor: pointer;
        transition: all .5s ease;
        text-decoration: none;
        overflow: hidden;
    }
    
    .nav-item:hover {
        font-size: 60px
    }

    a {
        color: white;
        text-decoration: none;
    }

    .audioBtn {
        margin-left:55px;
        font-size: 80px;
    }
}
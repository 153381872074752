.social-media-icon {
    height: 40px;
    width: 40px;
    margin-left: 25px;
    margin-right: 25px;
    cursor: pointer
}

.svg-icon {
    height: 40px;
    width: 40px;
}
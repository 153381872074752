#Home {
  width: 100%;
  height: 100vh;
  background-image: url("https://cdn.allwallpaper.in/wallpapers/1920x1080/10604/beautiful-sun-red-sky-1920x1080-wallpaper.jpg");
  background-size: 100% 100%;
  position: relative;
  transition: all 1s;
  .wrapper {
    width: 100%;
    height: 100%;
  }

  .image {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100vh;
    filter: grayscale(1);
    transition: all .5s;
  }

  h2 {
      text-align: right;
      margin: 10px 0
  }

  section .screen:hover .image {
      filter: grayscale(0)
  }

  section {
    position: relative;
    height: 100vh;
    display: flex;
    background: #000;
  }

  section .screen {
    position: relative;
    width: 33.3333%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    overflow: hidden;
    transition: 1s;
  }

  section:hover .screen {
    opacity: 0.3;
  }

  section .screen:hover {
    opacity: 1;
    width: 50%;
  }

  section .screen:nth-child(1) {
    background: rgb(85, 85, 85);
  }

  section .screen:nth-child(2) {
    background: rgb(85, 85, 85);
  }

  section .screen:nth-child(3) {
    background: rgb(85, 85, 85);
  }

  section .screen .content {
    position: absolute;
    width: 350px;
    height: 500px;
    background: transparent;
    padding: 40px;
    color: white;
    font-weight: bold; 
    font-size: 40px;
    box-sizing: border-box;
    transform: translateX(100%);
    transition: 1s;
    text-align: justify;
  }

  section .screen:hover .content {
    transform: translateX(0%);
    transition-delay: 0.5s;
    text-align: right
  }
}

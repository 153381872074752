@import "./mixin";

#TabContent {
  box-sizing: border-box;
  width: 100%;
  @include fontFamily;

  .content-inner {
    width: 50%;
    margin: auto;

    @include breakpoint($tablet) {
      width: 70%;
    }

    @include breakpoint($mobileL) {
      width: 95%;
    }
  }

  .skills-content {
    width: 100%;
  }

  .progress-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px 0;
  }

  .stack-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    width: 60px;
    margin-right: 20px;

    @include breakpoint($mobileL) {
      width: 40px;
      margin-right: 15px;
    }
  }

  .stack-img-wrapper {
    width: 40px;
    margin: auto;

    @include breakpoint($mobileL) {
      width: 30px;
    }
  }

  .stack-img-wrapper img {
    width: 100%;
  }

  .stack-name {
    @include breakpoint($mobileL) {
      font-size: 14px;
    }

    @include breakpoint($mobileM) {
      font-size: 12px;
    }

    @include breakpoint($mobileS) {
      font-size: 11px;
    }
  }

  .react-sweet-progress-line {
    background-color: #d8d8d8;
  }

  .react-sweet-progress-line-inner {
    min-height: 5px;
    transition: width 1s ease;
  }

  .react-sweet-progress-line-inner-status-active:before {
    animation: none;
  }

  .react-sweet-progress-symbol {
    display: none;
  }
}
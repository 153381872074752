@import "./mixin";

#About {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 0;
  @include fontFamily;

  p {
    margin: 0;
  }

  .title-background {
    background-image: url("../img/portfolio/mouse.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0 60%;
    height: 150px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    @include breakpoint($mobileL) {
      height: 120px;
    }
  }

  .title {
    color: black;
    text-align: center;
    font-size: 35px;
    font-weight: 600;

    @include breakpoint($mobileL) {
      font-size: 30px;
    }
  }

  .photo-section {
    width: 100%;
    max-width: 1900px;
    margin: auto;
  }

  .inner-wrapper {
    width: 65%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: nowrap;
    margin: 80px auto;
    align-items: center;

    @include breakpoint($tabletL) {
      width: 85%;
    }

    @include breakpoint($mobileL) {
      flex-wrap: wrap;
    }
  }

  .left-wrapper {
    width: 400px;
    margin-right: 90px;

    @include breakpoint($tablet) {
      width: 250px;
      margin-right: 50px;
    }

    @include breakpoint($mobileL) {
      width: 100%;
      margin: 0 0 20px 0;
    }
  }

  .photo-wrapper {
    width: 100%;

    @include breakpoint($mobileL) {
      text-align: center;
    }
  }

  .profile-photo {
    width: 100%;
    object-fit: contain;
    border-radius: 5px;

    @include breakpoint($mobileL) {
      width: 300px;
      margin: auto;
    }
  }

  .right-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include breakpoint($mobileL) {
      width: 100%;
    }
  }

  .about-me {
    font-weight: 1000;
    font-size: 40px;
    margin: 0;
    line-height: 1.5;
  }

  .about-me-text {
    font-size: 25px;
    margin: 30px 0;

    @include breakpoint($tablet) {
      font-size: 21px;
    }

    @include breakpoint($mobileL) {
      font-size: 20px;

    }
  }
}
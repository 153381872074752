@import "./mixin";

#Skills {
  box-sizing: border-box;
  width: 100%;
  height: 530px;
  margin: 0;
  padding: 0;
  @include fontFamily;

  p {
    margin: 0;
  }

  .skills-section-inner-wrapper {
    width: 100%;
    background-image: url("../img/portfolio/keyboard.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 530px;
  }

  .inner {
    margin: auto;
    padding: 40px 0;
    width: 100%;
  }

}
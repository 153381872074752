@import "./mixin";

#Footer {
  box-sizing: border-box;
  width: 100%;
  @include fontFamily;
  background: black;

  .inner-wrapper {
    width: 100%;
    padding: 40px 0;
    text-align: center;
  }

  .icon-wrapper {
    width: 120px;
    margin: auto;
  }

  .icon {
    width: 100%;
  }
  
  .copy-right {
    text-align: center;
    color: white;
    margin: 15px 0;
  }
}
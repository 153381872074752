@import "./mixin";
// @import url("https://fonts.googleapis.com/css2?family=Baloo+2:wght@600;700;800&family=Poppins:wght@400;600;900&display=swap");

#Portfolio-side-bar {
  // background: #333;
  // height: 100vh;
  @include fontFamily;
  width: calc(100% - 70px);
  background: rgba(255, 255, 255, 0.9);
  box-sizing: border-box;
  display: flex;
  overflow-y: hidden;
  position: absolute;
  z-index: 99;
  top: 0;
  right: 0;

  @include breakpoint($mobileL) {
    width: 100%;
    position: fixed;
  }

  ul {
    list-style: none;
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 0;
    margin: 0;
  }

  .nav-item {
    padding: 25px 0;
    visibility: visible;

    @include breakpoint($mobileL) {
      padding: 15px 0;
    }
  }

  .nav-item>a {
    text-decoration: none;
    font-size: 55px;
    color: black;

    @include breakpoint($mobileL) {
      font-size: 45px;
    }
  }
}

.isOpen {
  height: 100vh;
  transition: height 0.7s ease-in;
}

.isNotOpen {
  height: 0;
  transition: height 0.7s ease-in;
}

.itemIsOpen {
  opacity: 1;
  transition: all 0.7s ease-in 0.7s
}

.itemIsNotOpen {
  opacity: 0;
  transition: all 0.7s ease-in 0.7s
}

.itemIsOpenTwo {
  opacity: 1;
  transition: all 0.7s ease-in 1s
}

.itemIsNotOpenTwo {
  opacity: 0;
  transition: all 0.7s ease-in 1s
}

.itemIsOpenThree {
  opacity: 1;
  transition: all 0.7s ease-in 1.3s
}

.itemIsNotOpenThree {
  opacity: 0;
  transition: all 0.7s ease-in 1.3s
}
.App {
  text-align: center;
}

.middle {
  width: 100%;
  height: 100%;
  position: absolute;
}

.card {
  cursor: pointer;
  width: 22%;
  height: 500px;
  margin: 0 0 35px 0;
  position: relative;
  flex: 0 0 22%;
}

.card:nth-child(4n-1) {
  margin-left: 2%;
  margin-right: 4%;
}

.card:nth-child(4n-2) {
  margin-left: 4%;
  margin-right: 2%;
}

.front,
.back {
  width: 100%;
  height: 100%;
  overflow: hidden;
  backface-visibility: hidden;
  position: absolute;
  transition: transform 0.6s linear;
  border-radius: 5px
}

.front img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top;
  image-orientation: 0deg
}

.front {
  transform: perspective(600px) rotateY(0deg);
}

.back {
  background: linear-gradient(to right bottom, #ffffff, #ebebeb, #d8d8d8, #c5c5c5, #b2b2b2);
  transform: perspective(600px) rotateY(180deg);
}

.back-content {
  color: #2c3e50;
  width: 100%;
}

.card:hover>.middle>.front {
  transform: perspective(600px) rotateY(-180deg);
}

.card:hover>.middle>.back {
  transform: perspective(600px) rotateY(0deg);
}

.carousel,
.carousel-slider {
  height: 100vh
}

.red {
  color: red
}

.bg {
  background: red
}
#Carousel {
    height: 100vh;
  .imgBox {
    display: grid;
    height: 100%;
  }
  .carouselImage {
    max-width: 100%;
    max-height: 100vh;
    margin: 0;
    filter: grayscale(100%);
    object-fit: cover;
    object-position: center;
    transition: all 3s
  }

  .imgBox:hover > .carouselImage {
      filter: grayscale(0%)
  }

  .carouselContent {
      position: absolute;
      background: transparent;
      bottom: 30%;
      right: 150px;
      transform: translateY(50%);
      font-size: 70px;
      transition: all 1.5s;
      opacity: 0;
      width: 500px;
      text-align: left
  }

  .contentTitle {
      font-size: 80px
  }

  .content {
      font-size: 50px
  }

  .imgBox:hover > .carouselContent {
      transform: translateY(15%);
      transition-delay: .5s;
      opacity: 1
  }
}

@import "./mixin";

#Modal-Body {
  box-sizing: border-box;
  width: 100%;
  // height: calc(100vh - 9.5rem);

  @include fontFamily;

  p {
    margin: 0;
  }

  .inner-wrapper {
    display: flex;
    width: 95%;
    height: inherit;
    margin: auto;
    justify-content: space-between;
    align-items: flex-start;
    @include breakpoint($mobileL) {
      flex-wrap: wrap;
    }
  }

  .left-wrapper {
    width: 50%;
    height: inherit;
    display: flex;
    align-items: center;
    @include breakpoint($mobileL) {
      width: 100%;
      height: auto;
    }
  }

  .carousel-wrapper {
    width: 100%;
  }

  .right-wrapper {
    width: 45%;
    height: inherit;
    flex-wrap: nowrap;
    max-height: 100%;
    overflow-y: auto;
    @include breakpoint($mobileL) {
      width: 100%;
      height: auto;
      flex-wrap: wrap;
      max-height: none;
      margin-top: 35px;
    }
  }

  .img-wrapper .slick-img {
    width: 100%;
  }

  .img-description {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    margin: 10px 0 0 0;
  }

  .modal-title {
    font-size: 30px;
    font-weight: 700;
    line-height: 1.5;
  }

  .modal-description {
    margin: 20px 0
  }

  .modal-job-scope {
    margin: 40px 0;
  }

  .job-scope {
    font-size: 30px;
    @include breakpoint($mobileL) {
      font-size: 25px;
    }
  }

  .modal-list-item {
    margin: 10px 0;
  }

  .slick-prev:before, .slick-next:before {
    color: rgb(163, 163, 163);
  }
}
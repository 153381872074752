@import "./mixin";

#Single-Project {
  box-sizing: border-box;
  width: 100%;
  flex: 1;
  // height: calc(100vh - 9.5rem);

  @include fontFamily;

  p {
    margin: 0;
  }

  .inner-wrapper {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    padding: 40px 0;
    width: 95%;
    height: 100%;
    margin: auto;
    flex-wrap: wrap;
    align-items: flex-start;

    @include breakpoint($mobileL) {
      border: none
    }
  }

  .left-wrapper {
    width: 100%;
    display: flex;
  }

  .carousel-wrapper {
    width: 100%;
  }

  .right-wrapper {
    width: 100%;
    flex-wrap: nowrap;
    overflow-y: auto;
    margin-top: 35px;
  }

  .img-wrapper .slick-img {
    width: 100%;
  }

  .modal-title {
    font-size: 30px;
    font-weight: 700;
    line-height: 1.5;
    @include breakpoint($mobileL) {
      font-size: 25px;
    }
  }

  .modal-description {
    margin: 20px 0
  }

  .modal-job-scope {
    margin: 40px 0;
  }

  .job-scope {
    font-size: 30px;
    @include breakpoint($mobileL) {
      font-size: 25px;
    }
  }

  .modal-list-item {
    margin: 10px 0;
  }
}
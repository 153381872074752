@import "./mixin";

#Portfolio {
  // background: #333;
  // height: 100vh;
  box-sizing: border-box;
  display: flex;
  justify-content: space-evenly;
  @include fontFamily;

  h1 {
    margin: 0;
    width: fit-content;
    font-size: 55px;
    font-weight: 800;

    @include breakpoint($mobileL) {
      font-size: 40px;
      margin: auto;
    }
  }

  p {
    margin: 0;
  }

  .landing {
    background-image: url("../img/portfolio/door.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    width: 100%;
    position: relative;
  }

  .landing-box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: fit-content;
    background: rgba(255, 255, 255, 0.3);
    padding: 80px 140px;
    box-sizing: border-box;

    @include breakpoint($mobileL) {
      padding: 50px 0;
    }
  }

  .landing-text-box {
    text-align: center;
    margin: 0 0 40px 0;
  }

  .upper-text {
    font-size: 65px;
    font-weight: 600;
    margin-bottom: 20px;

    @include breakpoint($mobileL) {
      font-size: 35px;
    }
  }

  .lower-text {
    font-size: 25px;
    margin: 20px 0;

    @include breakpoint($mobileL) {
      font-size: 18px;
    }
  }

  .landing-icons-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
  }

  .social-media-icon-wrapper {
    display: flex;
    margin-top: 55px;

    @include breakpoint($mobileL) {
      justify-content: center;
    }
  }
}

.modal-90w {
  width: 90%;
  max-width: none !important;
  @include breakpoint($mobileL) {
    margin: auto;
    padding: 0;
    width: 100%;
  }
}

.modal-dialog-scrollable {
  @include breakpoint($mobileL) {
    max-height: 100vh;
  }
}

@import "./mixin";
// @import url("https://fonts.googleapis.com/css2?family=Baloo+2:wght@600;700;800&family=Poppins:wght@400;600;900&display=swap");

#Portfolio-nav {
  @include fontFamily;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 50px;

  a {
    color: #212529;
    text-decoration: none;
  }

  .icon {
    font-size: 25px;
  }

  .icon-y {
    @include iconFamily
  }

  .nav {
    list-style: none;
    display: flex;
    justify-content: flex-start;
    box-sizing: border-box;
    align-items: center;
    margin: 0;
  }

  .nav-item {
    margin: 0 25px;
    padding: 10px;
    cursor: pointer;
    position: relative;

    &:before {
      content: "";
      width: 0;
      height: 2px;
      background: black;
      position: absolute;
      bottom: 0;
      left: 50%;
      transition: all 0.5s ease-out;
    }

    &:hover {
      &:before {
        width: 100%;
        left: 0
      }
    }
  }
}
@import "./mixin";

#NoMatch {
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  @include fontFamily;

  p {
    margin: 0;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }

  .message {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translateX(-50%);
    @include breakpoint($mobileL) {
      width: 85%;
    }
  }

  .sorry {
    font-size: 50px;
    font-weight: 500;
    margin: 15px 0;

    @include breakpoint($mobileL) {
      font-size: 28px;
    }
  }

  .description {
    font-size: 20px;
    @include breakpoint($mobileL) {
      font-size: 17px;
    }
  }

  .flower {
    width: 60%;
    position: fixed;
    bottom: 0;
    left: 0;
    @include breakpoint($mobileL) {
      width: 100%;
    }
  }
}
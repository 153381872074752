#Gallery {
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-attachment: fixed;

    .loading {
        opacity: 0;
        transition: opacity 1s ease-in;
    }
    .loaded {
        opacity: 1;
        transition: opacity 1s ease-in;
    }

  .jumbotron {
      width: 80%;
      margin: auto;
      background: rgba(0, 0, 0, .4);
      padding: 30px 50px;
      color: white;
      box-sizing: border-box;
      position: relative;
  }

  .jumbotron h1 {
      font-size: 70px;
      font-weight: bold
  }

  .jumbotron p {
      font-size: 40px
  }

  .up {
      cursor: pointer
  }

  .down {
      height: 0;
      opacity: 0;
      margin-top: -10px;
      transition: all 1s
  }

  .up:hover ~ .down {
      opacity: 1;
      margin-top: 0;
      height: 15px
  }

  .cardWrapper {
      width: 90%;
      margin: 40px auto;
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
  }
}

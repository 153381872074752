@import "./mixin";

#Tabbar {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  @include fontFamily;

  .tab-items {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    box-sizing: border-box;
    cursor: pointer;
    padding-bottom: 5px;
  }

  .tab-text {
    font-size: 18px;
    font-weight: 600;
    margin-right: 5px;

    @include breakpoint($mobileS) {
      font-size: 15px;
    }
  }
}